import { get, concat, maxBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { regeneratePurchaseOrders } from '../actions/purchase_order';
import { createAddTemp } from '../actions/temp';
import Status from '../constants/Status';

import { BASE_ZINDEX } from '../popup-factory';

class RegeneratePurchaseOrdersPopup extends Component {

  constructor(props) {
    super(props);
    const shipping = this.determineShipping(props);
    this.state = {
      shipping,
      selected: shipping.reduce((o, s) => {
        o[s.shipping_id] = s.default_value;
        return o;
      }, {})
    };
  }

  determineShipping({ purchase_orders, shipping, quirks }) {
    const submitted_status = this.props.statuses[Status.PURCHASE_ORDER_SUBMITTED];
    const po_ids = purchase_orders.map(po => po.purchase_order_id);
    const extra_pos = purchase_orders.filter(po => !po.ext_po_id && !shipping.reduce((pos, s) => pos.concat(s.purchase_orders), []).map(spo => spo.purchase_order_id).includes(po.purchase_order_id));
    const result = Object.values(shipping.filter(s =>
      !s.purchase_orders.length || s.purchase_orders.map(po => po.purchase_order_id).some(po_id => po_ids.includes(po_id))
    ).reduce((o, s) => {
      const generate_phrase = s.purchase_orders.length ? "Don't regenerate" : "Don't generate";
      const hasInventory = s.purchase_orders.reduce((hi, po) => {
        return hi || po.items.reduce((ihi, i) => ihi || i.has_inventory, false);
      }, false);
      const isSubmitted = s.purchase_orders.reduce((submitted, po) => {
        return submitted || po.status.flow_order >= submitted_status.flow_order;
      }, false);
      o[s.shipping_id] = {
        shipping_id: s.shipping_id,
        form_number: s.purchase_orders.map(po => po.form_number).join(', '),
        details: `${s.origin.division_name} > ${s.destination.destination_name}`,
        status_name: maxBy(s.purchase_orders || [], 'status.flow_order')?.status?.status_name ?? '',
        default_value: s.purchase_orders.length ? s.purchase_orders[0].form_number : 'new',
        options: [
          /* Uncomment this if we want to prevent submitted POs from being regenerated
            !isSubmitted &&
           */
          !s.purchase_orders.reduce((bi, po) => {
            return bi || po.buy_inventory || po.items.reduce((ihi, i) => ihi || i.has_inventory, false);
          }, false) && { key: 'new', value: 'Create new PO#' },
          { key: 'keep', value: generate_phrase },
          ...(hasInventory
            /* Uncomment this if we want to prevent submitted POs from being regenerated
             || isSubmitted
             */
            ? [] : concat(
            s.purchase_orders.filter(po => {
              return !!get(quirks, [po.supplier_id, 'ALLOW_RESUBMIT']) || !po.ext_po_id;
            }),
            extra_pos
          ).map(po => {
            return { key: po.form_number, value: `Regenerate as #${po.form_number}` };
          }))
        ].filter(o => !!o)
      };
      return o;
    },
    purchase_orders.reduce((o, po) => {
      o[po.shipping_id] = {
        shipping_id: po.shipping_id,
        form_number: po.form_number,
        details: `${po.origin.division_name} > ${po.destination.destination_name}`,
        default_value: 'delete',
        options: [
          { key: 'keep', value: "Do not delete" },
          { key: 'delete', value: 'Delete' }
        ]
      };
      return o;
    }, {})));
    return result;
  }

  UNSAFE_componentWillReceiveProps(props) {
    const shipping = this.determineShipping(props);
    this.setState({
      shipping,
      selected: shipping.reduce((o, s) => {
        o[s.shipping_id] = s.default_value;
        return o;
      }, {})
    });
  }

  render() {
    const { order_id, onClosePopup, onRegeneratePurchaseOrders, index } = this.props;
    const { shipping } = this.state;

    const handleSelect = shipping_id => e => {
      const value = e.target.value;
      let selected = Object.assign({}, this.state.selected, {[shipping_id]: value});
      // Deal with shipping with same form number
      selected = Object.assign(selected, shipping.filter(s => s.form_number?.toString() === value?.toString() && s.shipping_id !== shipping_id).reduce((o, po) => {
        o[po.shipping_id] = 'delete';
        return o;
      }, {}));
      // Deal with POs with no items on them
      if ('keep' === value) {
        // Find all state.shipping with state.selected = shipping.form_number of this
        const form_number = shipping.filter(s => s.shipping_id === shipping_id)[0].form_number;
        selected = Object.assign(selected, Object.keys(this.state.selected).filter(s_id => this.state.selected[s_id]?.toString() === form_number?.toString() && s_id !== shipping_id).reduce((o, s_id) => {
          o[s_id] = 'new';
          return o;
        }, {}));
      }
      this.setState({ selected });
    };

    return (
      <div id="regenerate-pos-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Regenerate Purchase Orders</h3>
          </div>
        </div>
        <div className="row popup-content">
          <div className="small-12 columns">
            <table>
              <thead>
                <tr>
                  <th>Existing PO #</th>
                  <th>PO Details</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {shipping.map((po, idx) =>
                <tr key={idx}>
                  <td>{po.form_number}</td>
                  <td>{po.details}{po.default_value === 'delete' ? <b><br />There are no items currently associated with this PO.</b> : null}</td>
                  <td>{po.status_name}</td>
                  <td>
                    <fieldset>
                      {po.options.map((o, i) =>
                        <span key={i}>
                          <label>
                            <input
                              type="radio"
                              value={o.key}
                              checked={this.state.selected[po.shipping_id]?.toString() === o.key?.toString()}
                              onChange={handleSelect(po.shipping_id)}
                            />
                            {o.value}
                          </label>
                        </span>
                      )}
                    </fieldset>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
            <a className="button" onClick={e => onRegeneratePurchaseOrders(order_id, this.state.selected)}>Regenerate</a>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    quirks: get(state.entities, ['promostandards_quirks']),
    statuses: state.entities.statuses
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegeneratePurchaseOrders: (order_id, shipping) => {
      dispatch(regeneratePurchaseOrders(order_id, shipping));
      dispatch(createAddTemp('SELECTED_PURCHASE_ORDERS', {}));
      dispatch(closePopup());
    },
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedRegeneratePurchaseOrdersPopup = connect(mapStateToProps, mapDispatchToProps)(RegeneratePurchaseOrdersPopup);
export default ConnectedRegeneratePurchaseOrdersPopup;
